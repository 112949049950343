import http from '@/utils/http';


export const getHeros = (parameter) => {
  return http.request({
    url: '/v1/relation/games/hero/list',
    method: 'get',
    params: parameter
  })
}
