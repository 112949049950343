import http from '@/utils/http';

export const updateDetail = (parameter) => {
  return http.request({
    url: '/v1/relation/games/detail/update',
    method: 'post',
    data: parameter
  })
}

export const deleteDetail = (parameter) => {
    return http.request({
      url: '/v1/relation/games/detail/delete',
      method: 'post',
      data: parameter
    })
  }

export const updateDetailSource = (parameter) => {
  return http.request({
    url: '/v1/relation/games/detail/update_source',
    method: 'post',
    data: parameter
  })
}

export const getDetailSource = (parameter) => {
  return http.request({
    url: '/v1/relation/games/detail/get_source',
    method: 'get',
    params: parameter
  })
}

export const updateDetailSourceTeamrev = (parameter) => {
  return http.request({
    url: '/v1/relation/games/detail/update_source_teamrev',
    method: 'post',
    data: parameter
  })
}